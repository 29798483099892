import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Container,
  Link,
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import Pricing from "../components/Pricing.js";
import PaymentsService from "../services/payments-service.js";
import { AppContext } from "../components/AppContext.js";

const HomePage = () => {
  const auth = getAuth();
  const { state, setState } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const getUserEntitlements = async () => {
    const currentUserUID = auth.currentUser?.uid;

    if (currentUserUID) {
      try {
        const response = await PaymentsService.fetchActiveEntitlements(
          currentUserUID
        );
        const data = response.data;
        if (data.entitlements === undefined || data.entitlements === null) {
          throw new Error("Entitlements not found");
        }

        const entitlements = data.entitlements;
        let isImpromptuSpeakingEnabled = false;
        let isInterviewPracticeEnabled = false;

        for (const entitlement of entitlements) {
          console.log("Entitlement:", entitlement); // TBR for production
          if (entitlement.lookup_key === "impromptu_speaking_01") {
            isImpromptuSpeakingEnabled = true;
          }
          if (entitlement.lookup_key === "interview_practice_01") {
            isInterviewPracticeEnabled = true;
          }
        }

        setState((prevState) => ({
          ...prevState,
          isImpromptuSpeakingEnabled,
          isInterviewPracticeEnabled,
        }));

        console.log("Entitlement managed locally:", state); // TBR for production
      } catch (error) {
        console.error("Error fetching/assigning entitlements: ", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.currentUser) {
      getUserEntitlements();
    } else {
      setLoading(false);
    }
  }, [auth.currentUser]);

  const navigateToImpromptSpeakingPracticePage = () => {
    navigate("/imprompt");
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, pt: 4, pb: 4, fontFamily: "Roboto" }}>
      <Box sx={{ textAlign: "center", mb: 8 }}>
        <Typography variant="h3" gutterBottom>
          Welcome{" "}
          <span
            style={{
              color: "#FA735B",
              fontWeight: "bold",
              paddingLeft: "3px",
              paddingRight: "3px",
            }}
          >
            {auth.currentUser.displayName.split(" ")[0]}
          </span>
        </Typography>
        <Typography variant="h6" sx={{ mb: 4 }} gutterBottom>
          Find your voice and sharpen your skills with Speachy! Whether you're
          preparing for an interview or working on your spontaneous speaking, we
          help your practise and provide personalised & impartial feedback to
          help you improve.
        </Typography>
      </Box>

      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h4"
          sx={{ textAlign: "center", mb: 4 }}
          gutterBottom
        >
          What are we working on today?
        </Typography>

        <Grid id="catalogue" container spacing={2}>
          <Grid item xs={0} sm={0} md={1}></Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Card
              variant="elevation"
              sx={{
                display: "flex",
                borderRadius: "10px",
                flexDirection: "row",
                minHeight: "110px",
                maxHeight: "130px",
              }}
            >
              <CardActionArea
                // disabled={!state.isImpromptuSpeakingEnabled}
                onClick={navigateToImpromptSpeakingPracticePage}
                sx={{ display: "flex", width: "100%" }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign={"center"}
                  >
                    🎤 Impromptu Speaking
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ textAlign: "center" }}
                    gutterBottom
                  >
                    Boost your impromptu speaking with practise and feedback!
                  </Typography>
                  {/* {!state.isImpromptuSpeakingEnabled && (
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{ textAlign: "center", margin: 1 }}
                    >
                      🔒 This is a paid feature
                    </Typography>
                  )} */}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Card
              variant="elevation"
              sx={{
                display: "flex",
                borderRadius: "10px",
                flexDirection: "row",
                minHeight: "110px",
                maxHeight: "130px",
              }}
            >
              <CardActionArea
                // disabled={!state.isInterviewPracticeEnabled}
                onClick={() => navigate("/interview")}
                sx={{ display: "flex", width: "100%" }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign={"center"}
                  >
                    👔 Job Interview Preparation
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ textAlign: "center" }}
                  >
                    Ace your interviews with practise questions and response
                    analysis!
                  </Typography>
                  {/* {!state.isImpromptuSpeakingEnabled && (
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{ textAlign: "center", margin: 1 }}
                    >
                      🔒 This is a paid feature
                    </Typography>
                  )} */}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={0} sm={0} md={1}></Grid>
        </Grid>
      </Box>

      <Box>
        <Typography
          variant="subtitle1"
          sx={{ fontStyle: "italic", textAlign: "center" }}
        >
          Stay tuned for new features and scenarios coming soon!
        </Typography>
      </Box>

      {/* {!loading && !state.isImpromptuSpeakingEnabled && <Pricing></Pricing>} */}

      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => console.log("Backdrop is closed.")}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </Container>
  );
};

export default HomePage;
